import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

export const Item = styled.div`
  width: 100%;
`;

export const Head = styled.div`
  cursor: pointer;
`;

export const Body = styled.div`
  height: 0;
  overflow: hidden;
  transition: height 0.5s ease-in-out;
`;

export const BodyMask = styled.div`
  padding: 0;
  margin: 0;
`;

type Props = {
  className?: string;
  head: JSX.Element | React.ReactNode;
  body: JSX.Element | React.ReactNode;
  isOpen?: boolean;
  handleOpen?(): void;
  onOpenChange?(open: boolean): void;
  alwaysOpen?: boolean;
};

const Accordion = (props: Props) => {
  const ref = useRef();
  const { isOpen = false, alwaysOpen = false } = props;
  const [open, setOpen] = useState(alwaysOpen ? true : isOpen);

  const updateBodyHeight = useCallback(() => {
    const el = ref.current as HTMLElement;
    if (el) {
      // for some reason open is not upto date state which causes a close
      setOpen(open => {
        const bodyEl = el.querySelector(Body.toString()) as HTMLElement;
        if (!open) {
          // we close
          bodyEl.style.height = '0px';
          el.classList.remove('open');
        } else {
          requestAnimationFrame(() => {
            requestAnimationFrame(() => {
              // add .open to itemel
              el.classList.add('open');

              const height = bodyEl
                .querySelector(BodyMask.toString())
                .getBoundingClientRect().height;
              // we open
              bodyEl.style.height = `${height}px`;
            });
          });
        }

        return open;
      });
    }
  }, [ref.current, open]);

  useEffect(() => {
    const el = ref.current as HTMLElement;

    const resizeHandler = () => {
      updateBodyHeight();
    };

    window.addEventListener('resize', resizeHandler);

    if (el) {
      const body = el.querySelector(Body.toString()) as HTMLElement;
      let observer = new MutationObserver(() => {
        updateBodyHeight();
      });

      const config = { childList: true, subtree: true, attributes: true };

      observer.observe(body, config);

      return () => {
        window.removeEventListener('resize', resizeHandler);
        observer.disconnect();
        observer = null;
      };
    }
  }, []);

  useEffect(() => {
    updateBodyHeight();
  }, [open]);

  useEffect(() => {
    if (open !== isOpen) {
      setOpen(isOpen);
    }
  }, [isOpen]);

  return (
    <Item ref={ref} className={props.className} data-testid="Accordion">
      <Head
        className="Accordion--Head"
        data-testid="Accordion--Head"
        onClick={() => {
          if (!alwaysOpen) {
            if (typeof props.handleOpen === 'function') {
              props.handleOpen();
            } else {
              setOpen(!open);
              props?.onOpenChange?.(!open);
            }
          }
        }}
      >
        {props.head}
      </Head>
      <Body
        className="Accordion--Body"
        data-testid="Accordion--Body"
        data-teststate={'Accordion--' + (open ? 'open' : 'closed')}
      >
        <BodyMask>{props.body}</BodyMask>
      </Body>
    </Item>
  );
};

export default Accordion;
